<template>
    <main class="main-page">
    <template v-if="showHeader">
        <section class="page-section mb-3" >
            <div class="container">
                <div class="grid align-items-center">
                    <div  v-if="!isSubPage"  class="col-fixed " >
                        <Button @click="$router.go(-1)" label=""  className="p-button p-button-text " icon="pi pi-arrow-left"  />
                    </div>
                    <div  class="col " >
                        <div class="" >
                            <div class="flex align-items-center ">
                                <div class="mr-3">
                                    <Avatar size="large" class="" icon="pi pi-user" />
                                    </div>
                                    <div>
                                        <div class="text-2xl text-primary font-bold">User Account Details</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div >
                            <div style="min-height:100px">
                                <template v-if="pageReady">
                                    <div class="grid justify-content-start">
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">User Type: </div>
                                                <div class="col font-bold">{{$utils.upper( item.user_type )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">First Name: </div>
                                                <div class="col font-bold">{{$utils.upper( item.first_name )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Last Name: </div>
                                                <div class="col font-bold">{{$utils.upper( item.last_name )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Email: </div>
                                                <div class="col font-bold">{{$utils.upper( item.email )}}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Mobile Number: </div>
                                                <div class="col font-bold">{{ item.mobile_number }}</div>
                                            </div>
                                        </div>
                                        <div class="col-12 md:col-12">
                                            <div class="grid align-items-center card surface-50 p-3">
                                                <div class="col-fixed  text-500 font-medium">Date Created: </div>
                                                <div class="col font-bold">{{$utils.humanDatetime( item.date_created )}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="loading">
                                    <div>
                                        <table class="p-datatable-table w-full">
                                            <tbody>
                                                <tr v-for="n in 5" :key="n">
                                                    <td class="text-left"  style="width:100%">
                                                        <Skeleton height="2rem" class="m-2" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useViewPage } from '@/composables/viewpage.js';
	const props = defineProps({
		id: [String, Number],
		primaryKey: {
			type: String,
			default: 'user_id',
		},
		pageName: {
			type: String,
			default: 'users',
		},
		routeName: {
			type: String,
			default: 'usersview',
		},
		apiPath: {
			type: String,
			default: 'users/view',
		},
		editButton: {
			type: Boolean,
			default: true,
		},
		deleteButton: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Are you sure you want to delete this record?",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		}
	});
	const app = useApp(props);
	const page = useViewPage(props); // page hook
	const {  item, loading, pageReady } = toRefs(page.state);
	const { apiUrl, currentRecord } = page.computedProps
	const  { load, deleteItem, exportPage, moveToNextRecord, moveToPreviousRecord } = page.methods;
	function getActionMenuModel(data){
		return []
	}
	onMounted(()=>{ 
		const pageTitle = "User Account Details";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
